import { useEffect, useState } from 'react';

export default function useRunIntersectionObserver({ ref }: { ref: any }) {
  const [run, setRun] = useState(false);

  useEffect(() => {
    if (!process.browser) return;

    // Create the observer, same as before:
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setRun(entry.isIntersecting);
      });
    });

    ref.current && observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return { run };
}
