import { useEffect, useRef } from 'react';

function Index({
  opacity = 0.5,
  color = 'rgba(32, 177, 222, 0.7)',
  colorLine = 'rgba(163, 54, 217, 0.3)',
  id = 'canvas-network',
  numberOfParticles = 170,
  run = true,
}: {
  opacity?: number;
  color?: string;
  colorLine?: string;
  id?: string;
  numberOfParticles?: number;
  run?: boolean;
}): JSX.Element {
  const refAnimation = useRef<any>();

  useEffect(() => {
    const particles: Array<Particle> = [];
    const ratio = 1.2;

    const canvas = document.getElementById(id) as HTMLCanvasElement;
    const parent = canvas.parentElement;

    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    const canvasWidth = (canvas.width =
      parent?.clientWidth || window.innerWidth);

    const canvasHeight = (canvas.height =
      parent?.clientHeight || window.innerHeight);

    const mouse: { x: number; y: number; radius: number } = {
      x: 0,
      y: 0,
      radius: (canvas.width / 80) * (canvas.height / 80),
    };

    window.addEventListener('mousemove', (e: MouseEvent) => {
      mouse.x = e.clientX;
      mouse.y = e.clientY;
    });

    class Particle {
      x: number;
      y: number;
      directionX: number;
      directionY: number;
      size: number;
      speed: number;
      color: string;

      constructor(
        x: number,
        y: number,
        directionX: number,
        directionY: number,
        size: number,
        color: string,
      ) {
        this.x = x;
        this.y = y;
        this.directionX = directionX;
        this.directionY = directionY;
        this.size = size;
        this.color = color;
        this.speed = 0.6;
      }

      draw() {
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2, false);
        ctx.fillStyle = color;
        ctx.fill();
      }

      update() {
        if (this.x > canvasWidth || this.x < 0) {
          this.directionX = -this.directionX;
        }
        if (this.y > canvasHeight || this.y < 0) {
          this.directionY = -this.directionY;
        }

        this.x += this.directionX * this.speed;
        this.y += this.directionY * this.speed;
        this.draw();
      }
    }

    function init() {
      let _numberOfParticles = numberOfParticles;

      if (process.browser) {
        if (window.innerWidth < 768) _numberOfParticles = 60;
      }

      for (let i = 0; i < _numberOfParticles; i++) {
        const size = Math.random() * 5 + 1;
        const x =
          Math.random() * (canvasWidth - size * 2 - size * 2) + size * 2;
        const y =
          Math.random() * (canvasHeight - size * 2 - size * 2) + size * 2;

        if ((x * ratio * canvasHeight) / canvasWidth + y < canvasHeight)
          continue;

        const directionX = Math.random() * 5 - 2.5;
        const directionY = Math.random() * 5 - 2.5;
        const color = '#FFF';
        particles.push(new Particle(x, y, directionX, directionY, size, color));
      }
    }

    function animation() {
      refAnimation.current = requestAnimationFrame(animation);
      ctx.clearRect(0, 0, canvasWidth, canvasHeight);
      for (let i = 0; i < particles.length; i++) {
        particles[i].update();
      }
      connect();
    }

    function connect() {
      for (let i = 0; i < particles.length; i++) {
        for (let j = i; j < particles.length; j++) {
          const distance =
            (particles[i].x - particles[j].x) *
              (particles[i].x - particles[j].x) +
            (particles[i].y - particles[j].y) *
              (particles[i].y - particles[j].y);
          if (distance < (canvasWidth / 7) * (canvasHeight / 7)) {
            ctx.beginPath();
            ctx.moveTo(particles[i].x, particles[i].y);
            ctx.lineTo(particles[j].x, particles[j].y);
            ctx.strokeStyle = colorLine;
            ctx.stroke();
          }
        }
      }
    }

    init();

    if (run) {
      animation();
    } else {
      cancelAnimationFrame(refAnimation.current);
    }
  }, [color, colorLine, id, numberOfParticles, run]);

  return (
    <canvas
      id={id}
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: opacity,
      }}
    />
  );
}

export default Index;
