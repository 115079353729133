import styled from '@emotion/styled';
import { breakPoints } from 'constant/breakPoints';
import Particles from 'components/Particles/particles';
import { useRef } from 'react';
import useRunIntersectionObserver from 'hooks/useRunIntersectionObserver';

function ContactUs(): JSX.Element {
  const refWrap = useRef<HTMLDivElement>(null);
  const { run } = useRunIntersectionObserver({ ref: refWrap });

  return (
    <WrapMark>
      <Wrap className="text-center" ref={refWrap}>
        <h4 className="font-semibold">
          Are you ready to be the next partner?
          <br />
          Let’s work together
        </h4>
        {/* <p>
        Get in touch with us to receive a
        <br />
        <strong>FREE CONSULTATION</strong> and
        <strong> QUOTATION</strong>
      </p> */}

        <a href="/contact-us" className="button button-outline">
          Contact Us{' '}
          <svg
            width="20"
            height="25"
            viewBox="0 0 20 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.08984 16.58L13.6698 12L9.08984 7.41L10.4998 6L16.4998 12L10.4998 18L9.08984 16.58Z"
              fill="white"
            />
          </svg>
        </a>
        <div className="curve-top" />
        <div className="curve-bottom" />
        <Particles run={run} />
      </Wrap>
    </WrapMark>
  );
}

export default ContactUs;

const WrapMark = styled.div`
  position: relative;
`;

const Wrap = styled.section`
  padding: 120px 15px 110px;
  width: 100%;

  #canvas-network {
    position: absolute;
    top: 0;
    z-index: 1;
    right: 0;
    width: 80%;
    height: 100%;
    transform: rotateY(180deg) rotateZ(180deg);
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      171.32deg,
      rgba(134, 0, 200, 0.7) 45.78%,
      rgba(11, 9, 111, 0.7) 85.8%
    );
    opacity: 0.6;
    z-index: 0;
  }

  .curve-top,
  .curve-bottom {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    background-color: var(--purple-darken);
    height: 85px;
    z-index: 2;
  }

  .curve-top {
    top: 0;
    clip-path: ellipse(52% 100% at 50% 0%);
  }

  .curve-bottom {
    bottom: 0;
    clip-path: ellipse(52% 100% at 50% 100%);
  }

  h4 {
    font-size: 42px;
    margin: 0 0 8px;
    padding-bottom: 90px;
    position: relative;
    z-index: 10;
  }

  p {
    font-size: 16px;
    margin-bottom: 32px;

    strong {
      font-size: 20px;
    }
  }

  a {
    width: 188px;
    z-index: 100;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -60px);

    svg {
      vertical-align: middle;
    }
  }

  @media screen and (max-width: ${breakPoints.tabletM - 1}px) {
    padding: 220px 0 190px;

    .curve-top {
      top: 0;
      clip-path: ellipse(74% 100% at 50% 0%);
    }

    .curve-bottom {
      bottom: 0;
      clip-path: ellipse(74% 100% at 50% 100%);
    }

    h4 {
      font-size: 28px;
      padding-left: 28px;
      padding-right: 28px;
    }
  }

  @media screen and (min-width: ${breakPoints.tabletM}px) {
    padding: 242px 0 209px;
  }
`;
